const Modal = ({modalId}) => {
  const HandleClose =() =>{
    const element = document.getElementById(modalId);
    element.classList.remove('show');
    element.style.display = "none";
  }
  return (
    <>
      <div
        className="modal fade"
        tabIndex="-1"
        id={modalId}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Add New Category
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={(e) => HandleClose(e)}
              ></button>
            </div>
            <form className="forms-sample">
              <div className="modal-body">
                <label htmlFor="address" className="form-label">
                  Wilayas
                </label>
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  autoComplete="off"
                  placeholder="Address"
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e)=>HandleClose()}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Modal;
