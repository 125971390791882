import axiosInstance from "../Axios/axios";

const getAgentLeadApi = async () => {
  return await axiosInstance.get(`store/users/agents/get_lead`);
};

const getSuggessionUsersApi = async () => {
  return await axiosInstance.get(`store/users/agents/get_lead_customer`);
};

const getWilayasApi = async () => {
  return await axiosInstance.get(`store/general/get_wilayas`);
};

const getDairasApi = async (id) => {
  return await axiosInstance.get(`store/general/get_wilayas/${id}`);
};

const getSourcesApi = async () => {
  return await axiosInstance.get(`store/users/agents/get_sources`);
};

const getLeadProductsApi = async (data) => {
  return await axiosInstance.post(`store/users/agents/get_products`,data);
};

const setLeadStatusApi = async (id) => {
  return await axiosInstance.get(`store/users/agents/get_status/${id}`);
};

const getLeadUpdateStatusApi = async (id, data) => {
  return await axiosInstance.post(
    `store/users/agents/lead/${id}/update_status`,
    data
  );
};

const getCheckDeliveryApi = async (id, data) => {
  return await axiosInstance.post(
    `store/users/agents/lead/${id}/check_delivery`,
    data
  );
};

const getConfirmeLeadApi = async (id, data) => {
  return await axiosInstance.post(
    `store/users/agents/lead/${id}/confirme_lead`,
    data
  );
};
const getgetCheckProductQtyApi = async (data) => {
  return await axiosInstance.post(`store/users/agents/check_product_qty`, data);
};
const getUpdateLeadApi = async (id, data) => {
  return await axiosInstance.post(`store/users/agents/lead/${id}/update`, data);
};
const getLeadAddProductsApi = async (id, data) => {
  return await axiosInstance.post(`store/users/agents/lead/${id}/add_product`, data);
};
const getDeleteLeadItemApi = async (id, item_id) => {
  return await axiosInstance.delete(`store/users/agents/lead/${id}/item/${item_id}`);
};
export {
  getAgentLeadApi,
  getSuggessionUsersApi,
  getWilayasApi,
  getDairasApi,
  getSourcesApi,
  getLeadProductsApi,
  setLeadStatusApi,
  getLeadUpdateStatusApi,
  getCheckDeliveryApi,
  getConfirmeLeadApi,
  getgetCheckProductQtyApi,
  getUpdateLeadApi,
  getLeadAddProductsApi,
  getDeleteLeadItemApi
};
