import { handleErrorApi } from "../../../helpers/handleErrorApi";
import { OrderSettingsApi,UpdateOrderSettingsApi } from "../../../apis/app/order_settings/getOrderSettingsApi";
import Toast from "../../../helpers/toast";

const useOrderSettingsFunctions = () => {
  const getOrderSettings = async (setLoading, setData) => {
    try {
      setLoading(true);
      const response = await OrderSettingsApi();
      setData(response.data.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const updateOrderSettings = async (data, setLoading,setData) => {
    try {
      setLoading(true);
      const response = await UpdateOrderSettingsApi(data);
      setData(response.data.data);
      Toast("success", "Updated with Success");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  return {
    getOrderSettings,
    updateOrderSettings
  };
};
export default useOrderSettingsFunctions;
