import { Navigate } from "react-router-dom";
import ProtectedRoute from "./helpers/ProtectedRoute";
import NotFound from "./pages/NotFound";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ConfirmeAccount from "./pages/app/ConfirmeAccount/ConfirmeAccount";
import CreateStore from "./pages/app/store/CreateStore";
import MainDashboard from "./pages/app/dashboard/MainDashboard";
import ShowCategories from "./pages/app/Categories/ShowCategories";
import CreateCategory from "./pages/app/Categories/CreateCategory";
import UpdateCategory from "./pages/app/Categories/UpdateCategory";
import ShowSuppliers from "./pages/app/Suppliers/ShowSuppliers";
import CreateSupplier from "./pages/app/Suppliers/CreateSupplier";
import Factures from "./pages/app/Factures/Factures";
import Products from "./pages/app/Products/Products";
import CreateProduct from "./pages/app/Products/CreateProducts";
import ViewProduct from "./pages/app/Products/ViewProduct";
import UpdateProduct from "./pages/app/Products/UpdateProduct";
import CreateFacture from "./pages/app/Factures/CreateFacture";
import ViewFacture from "./pages/app/Factures/ViewFacture";
import UpdateFacture from "./pages/app/Factures/UpdateFacture";
import UpdateSupplier from "./pages/app/Suppliers/UpdateSupplier";
import DeliveryMans from "./pages/app/Delivery/Man/DeliveryMans";
import CreateDeliveryMan from "./pages/app/Delivery/Man/CreateDeliveryMan";
import ViewDeliveryMan from "./pages/app/Delivery/Man/ViewDeliveryMan";
import UpdateDeliveryMan from "./pages/app/Delivery/Man/UpdateDeliveryMan";
import DeliveryCompany from "./pages/app/Delivery/Company/DeliveryCompany";
import CreateDeliveryCompany from "./pages/app/Delivery/Company/CreateDeliveryCompany";
import ViewDeliveryCompany from "./pages/app/Delivery/Company/ViewDeliveryCompanyYalidine";
import UpdateDeliveryCompany from "./pages/app/Delivery/Company/UpdateDeliveryCompany";
import OrderStatus from "./pages/app/OrderStatus/OrderStatus";
import UpdateOrderStatus from "./pages/app/OrderStatus/UpdateOrderStatus";
import OrderSource from "./pages/app/OrderSource/OrderSource";
import UpdateOrderSource from "./pages/app/OrderSource/UpdateOrderSource";
import Customers from "./pages/app/Customers/Customers";
import Leads from "./pages/app/Orders/Leads";
import GoogleSheets from "./pages/app/GoogleSheets/GoogleSheets";
import ViewGoogleAccount from "./pages/app/GoogleSheets/ViewGoogleAccount";
import ViewGoogleAccountSheets from "./pages/app/GoogleSheets/ViewGoogleAccountSheets";
import ViewSheetFile from "./pages/app/GoogleSheets/ViewSheetFile";
import ListGoogleSheets from "./pages/app/GoogleSheets/ListGoogleSheets";
import ViewLeads from "./pages/app/Orders/ViewLead";
import ConfirmeLead from "./pages/app/Orders/ConfirmeLead";
import WaitList from "./pages/auth/WaitList";
import Maintenance from "./pages/Maintenance";
import MyProfile from "./pages/app/Profile/MyProfile";
import ViewDeliveryCompanyYalidine from "./pages/app/Delivery/Company/ViewDeliveryCompanyYalidine";
import Orders from "./pages/app/Orders/Orders";
import UpdateGoogleSheet from "./pages/app/GoogleSheets/UpdateGoogleSheet";
import MyStore from "./pages/app/store/MyStore";
import MySubscriptions from "./pages/app/Subscriptions/MySubscriptions";
import Paiements from "./pages/app/Paiements/Paiements";
import PaiementsDetails from "./pages/app/Paiements/PaiementsDetails";
import Agents from "./pages/app/Agents/Agents";
import CreateAgent from "./pages/app/Agents/CreateAgent";
import ViewLeadAgent from "./pages/agents/ViewLeadAgent";
import DeliveryManStock from "./pages/app/Delivery/Man/DeliveryManStock";
import CreateDeliveryManStock from "./pages/app/Delivery/Man/CreateDeliveryManStock";
import ViewDeliveryManStock from "./pages/app/Delivery/Man/ViewDeliveryManStock";
import OrderSettings from "./pages/app/OrderSettings/OrderSettings";
import YalidineTarifCommunes from "./pages/app/Delivery/Company/Yalidine/YalidineTarifCommunes";

const routes = (isLoggedIn, accountActivate, createStore) => [
  // Confirme Account
  {
    path: "/confirme_account",
    element:
      accountActivate === false ? (
        ProtectedRoute(
          isLoggedIn,
          ConfirmeAccount
        )
      ) : (
        <Navigate to="/" />
      ),
  },
  // Create Store
  {
    path: "/create_store",
    element:
      createStore === true ? (
        ProtectedRoute(isLoggedIn, CreateStore)
      ) : (
        <Navigate to="/" />
      ),
  },
  // Not Found
  {
    path: "*",
    // element: ProtectedRoute(isLoggedIn, NotFound),
    element: <NotFound />,
  },
  // Dashboard
  {
    path: "/",
    element: ProtectedRoute(isLoggedIn, MainDashboard),
  },
  // Categories
  {
    path: "/categories",
    element: ProtectedRoute(isLoggedIn, ShowCategories),
  },
  {
    path: "/categories/create",
    element: ProtectedRoute(isLoggedIn, CreateCategory),
  },
  {
    path: "/categories/update/:id",
    element: ProtectedRoute(isLoggedIn, UpdateCategory),
  },
  // Suppliers
  {
    path: "/suppliers",
    element: ProtectedRoute(isLoggedIn, ShowSuppliers),
  },
  {
    path: "/suppliers/create",
    element: ProtectedRoute(isLoggedIn, CreateSupplier),
  },
  {
    path: "/suppliers/update/:id",
    element: ProtectedRoute(isLoggedIn, UpdateSupplier),
  },
  // Factures
  {
    path: "/factures",
    element: ProtectedRoute(isLoggedIn, Factures),
  },
  {
    path: "/factures/create",
    element: ProtectedRoute(isLoggedIn, CreateFacture),
  },
  {
    path: "/factures/view/:id",
    element: ProtectedRoute(isLoggedIn, ViewFacture),
  },
  {
    path: "/factures/update/:id",
    element: ProtectedRoute(isLoggedIn, UpdateFacture),
  },
  // Products
  {
    path: "/products",
    element: ProtectedRoute(isLoggedIn, Products),
  },
  {
    path: "/products/create",
    element: ProtectedRoute(isLoggedIn, CreateProduct),
  },
  {
    path: "/products/view/:id",
    element: ProtectedRoute(isLoggedIn, ViewProduct),
  },
  {
    path: "/products/update/:id",
    element: ProtectedRoute(isLoggedIn, UpdateProduct),
  },
  // Agents
  {
    path: "/users/agents",
    element: ProtectedRoute(isLoggedIn, Agents),
  },
  {
    path: "/users/agents/create",
    element: ProtectedRoute(isLoggedIn, CreateAgent),
  },
  // Delivery Man
  {
    path: "/delivery/man",
    element: ProtectedRoute(isLoggedIn, DeliveryMans),
  },
  {
    path: "/delivery/man/create",
    element: ProtectedRoute(isLoggedIn, CreateDeliveryMan),
  },
  {
    path: "/delivery/man/view/:id",
    element: ProtectedRoute(isLoggedIn, ViewDeliveryMan),
  },
  {
    path: "/delivery/man/update/:id",
    element: ProtectedRoute(isLoggedIn, UpdateDeliveryMan),
  },
  {
    path: "/delivery/man/:id/stock",
    element: ProtectedRoute(isLoggedIn, DeliveryManStock),
  },
  { 
    path: "/delivery/man/:id/stock/create",
    element: ProtectedRoute(isLoggedIn, CreateDeliveryManStock),
  },
  {
    path: "/delivery/man/:id/stock/view/:stock_id",
    element: ProtectedRoute(isLoggedIn, ViewDeliveryManStock),
  },
  // Delivery Company
  {
    path: "/delivery/company",
    element: ProtectedRoute(isLoggedIn, DeliveryCompany),
  },
  {
    path: "/delivery/company/create",
    element: ProtectedRoute(isLoggedIn, CreateDeliveryCompany),
  },
  // View Yalidine Company
  {
    path: "/delivery/company/view/yalidine/:id",
    element: ProtectedRoute(isLoggedIn, ViewDeliveryCompanyYalidine),
  },
  {
    path: "/delivery/company/view/yalidine/:id/view/:wilaya_id",
    element: ProtectedRoute(isLoggedIn, YalidineTarifCommunes),
  },

  {
    path: "/delivery/company/update/:id",
    element: ProtectedRoute(isLoggedIn, UpdateDeliveryCompany),
  },
  // Orders 
  {
    path: "/orders",
    element: ProtectedRoute(isLoggedIn, Orders),
  },
  // Manage Orders
  // Status
  {
    path: "/orders/status",
    element: ProtectedRoute(isLoggedIn, OrderStatus),
  },
  {
    path: "/orders/status/update/:id",
    element: ProtectedRoute(isLoggedIn, UpdateOrderStatus),
  },
  // Sources
  {
    path: "/orders/sources",
    element: ProtectedRoute(isLoggedIn, OrderSource),
  },
  {
    path: "/orders/settings",
    element: ProtectedRoute(isLoggedIn, OrderSettings),
  },
  {
    path: "/orders/sources/update/:id",
    element: ProtectedRoute(isLoggedIn, UpdateOrderSource),
  },
  // Customers
  {
    path: "/customers",
    element: ProtectedRoute(isLoggedIn, Customers),
  },
  // Leads
  {
    path: "/leads",
    element: ProtectedRoute(isLoggedIn, Leads),
  },
  {
    path: "/leads/view/:id",
    element: ProtectedRoute(isLoggedIn, ViewLeads),
  },
  {
    path: "/leads/confirme/:id",
    element: ProtectedRoute(isLoggedIn, ConfirmeLead),
  },
  {
    path: "/leads_confirme",
    element: ProtectedRoute(isLoggedIn, ViewLeadAgent),
  },
  // Google Sheets
  {
    path: "/google_sheets",
    element: ProtectedRoute(isLoggedIn, ListGoogleSheets),
  },
  {
    path: "/google_sheets/update/:id",
    element: ProtectedRoute(isLoggedIn, UpdateGoogleSheet),
  },
  {
    path: "/google_sheets/create",
    element: ProtectedRoute(isLoggedIn, GoogleSheets),
  },
  {
    path: "/google_sheets/view/:id",
    element: ProtectedRoute(isLoggedIn, ViewGoogleAccount),
  },
  {
    path: "/google_sheets/view/:id/sheet/:sheet_id",
    element: ProtectedRoute(isLoggedIn, ViewGoogleAccountSheets),
  },
  {
    path: "/google_sheets/view/:id/sheet/:sheet_id/sync/:file_id",
    element: ProtectedRoute(isLoggedIn, ViewSheetFile),
  },
  // Shopify
  {
    path: "/shopify",
    element: ProtectedRoute(isLoggedIn, Maintenance),
  },
  // woocommerce
  {
    path: "/woocommerce",
    element: ProtectedRoute(isLoggedIn, Maintenance),
  },
  // youcan
  {
    path: "/youcan",
    element: ProtectedRoute(isLoggedIn, Maintenance),
  },
  // My Profile
  {
    path: "/profile",
    element: ProtectedRoute(isLoggedIn, MyProfile),
  },
  {
    path: "/my_store",
    element: ProtectedRoute(isLoggedIn, MyStore),
  }, 
  // {
  //   path: "/subscriptions",
  //   element: ProtectedRoute(isLoggedIn, MySubscriptions),
  // },
  // {
  //   path: "/subscriptions/paiement",
  //   element: ProtectedRoute(isLoggedIn, Paiements),
  // },
  // {
  //   path: "/subscriptions/paiement/:id",
  //   element: ProtectedRoute(isLoggedIn, PaiementsDetails),
  // },
  // ! Auth Pages
  {
    path: "/login",
    element: !isLoggedIn ? <Login /> : <Navigate to="/" />,
  },
  {
    path: "/signup_two",
    element: !isLoggedIn ? <SignUp /> : <Navigate to="/" />,
  },
  {
    path: "/signup",
    element: <WaitList />,
  },
  {
    path: "/forgot_password",
    element: !isLoggedIn ? <ForgotPassword /> : <Navigate to="/" />,
  },
];
export { routes };
