import { useNavigate } from "react-router-dom";
import { handleErrorApi } from "../../../helpers/handleErrorApi";
import Toast from "../../../helpers/toast.js";
import {
  getOrderLeadDetailsApi,
  getOrderLeadStatusApi,
  getUpdateOrderLeadStatusApi,
  getCustomersApi,
  getDeliveryInformationsApi,
  getStoreProductsApi,
  getConfirmeLeadApi,
  getUpdateOrderLeadApi,
  getDeliveryTypesApi,
  getLeadStatisticsApi,
  getLeadCheckDeliveryApi,
  getLeadProductsApi
} from "../../../apis/app/order_leads/getAllLeadsFunctions";
import { getWilayasApi,getWilayaDairasApi } from "../../../apis/app/delivery_mans/useDeliveryManApis";
import {getAllSourceApi } from "../../../apis/app/order_source/getOrderSourceApis";
const useAllFunctionOrderLeads = () => {

  const navigate = useNavigate();
  const getOrderLeadDetails = async (id, setLoading, setData) => {
    try {
      setLoading(true);
      const response = await getOrderLeadDetailsApi(id);
      setData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const getOrderLeadStatus = async (id, setDataStatus) => {
    try {
      const response = await getOrderLeadStatusApi(id);
      setDataStatus(response.data?.data);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
    }
  };
  const getUpdateOrderLeadStatus = async (id, data, setLoading) => {
    try {
      setLoading(true);
      const response = await getUpdateOrderLeadStatusApi(id, data);
      Toast("success", "Lead Status was updated");
      navigate(0);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const getStoreCustomers = async (id, setCustomers) => {
    try {
      const response = await getCustomersApi(id);
      setCustomers(response.data?.data);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
    }
  };
  const getWilayas = async (setWilayas,setLoading) => {
    try {
      setLoading(true);
      const response = await getWilayasApi();
      setWilayas(response?.data?.data);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
      setLoading(false);
    }finally {
      setLoading(false);
    }
  };
  const getWilayaDairas = async (id, setDairas,setLoading) => {
    try {
      setLoading(true);
      const response = await getWilayaDairasApi(id);
      setDairas(response?.data?.data?.daira);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
      setLoading(false);
    }finally{
      setLoading(false);
    }
  };
  const getLeadSources= async (setSources) => {
    try {
      const response = await getAllSourceApi("all");
      setSources(response?.data?.data);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
    }
  };
  const getDeliveryTypeDatas = async(id,setDeliveryDatas) =>{
    try {
      const response = await getDeliveryInformationsApi(id);
      setDeliveryDatas(response.data?.data);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
    }
  }
  const getStoreProducts = async(setProductDB) =>{
    try {
      const response = await getStoreProductsApi();
      setProductDB(response.data?.data);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
    }
  }
  const getConfirmeLead = async(id,data,setLoading) =>{
    try {
      setLoading(true);
      const response = await getConfirmeLeadApi(id,data);
      Toast('success',"Lead was confirmed");
      navigate('/leads');
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  }
  const getUpdateOrderLead = async(id,data,setLoading,setData)=>{
    try {
      setLoading(true);
      const response = await getUpdateOrderLeadApi(id, data);
      setData(response.data.data);
      Toast("success", "Lead was successfully updated");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  }
  const getDeliveryTypes = async (setData,setLoading)=>{
    try {
      setLoading(true);
      const response = await getDeliveryTypesApi();
      setData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  }
  const getLeadStatistics = async (setData,setLoading)=>{
    try {
      setLoading(true);
      const response = await getLeadStatisticsApi();
      setData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  }
  const getCheckDelivery = async (id, data, setDeliveryWarning,setDeliveryCost,setDeliveryCostAnou)=>{
    try {
      const response = await getLeadCheckDeliveryApi(id, data);
      if (response.data?.data?.exists === false) {
        setDeliveryWarning(
          <div className="row">
            <div className="alert alert-danger" role="alert">
            {response.data?.data?.message}
            </div>
          </div>
        );
        setDeliveryCost(0);
        setDeliveryCostAnou(0);
      } else {
        setDeliveryWarning(
          <div className="row">
            <div className="alert alert-warning" role="alert">
              Delivery fee is: {response.data?.data?.price} Da -{" "}
              {response.data?.data?.type}: {response.data?.data?.name}
            </div>
          </div>
        );
        setDeliveryCost(response.data?.data?.price);
        setDeliveryCostAnou(response.data?.data?.delivery_anounced);
      }
    } catch (error) {
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    }
  }
  const getLeadProducts = async (setProductDB,data) => {
    try {
      const response = await getLeadProductsApi(data);
      setProductDB(response.data?.data);
    } catch (error) {
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    }
  };
  return {
    getOrderLeadDetails,
    getOrderLeadStatus,
    getUpdateOrderLeadStatus,
    getStoreCustomers,
    getWilayas,
    getWilayaDairas,
    getLeadSources,
    getDeliveryTypeDatas,
    getStoreProducts,
    getConfirmeLead,
    getUpdateOrderLead,
    getDeliveryTypes,
    getLeadStatistics,
    getCheckDelivery,
    getLeadProducts
  };
};
export default useAllFunctionOrderLeads;
