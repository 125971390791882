import * as yup from "yup";
import { useState, useEffect } from "react";
import useAgentLeads from "../../hooks/agent/Leads/useAgentLeads";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../helpers/errorMessage";
import Toast from "../../helpers/toast";
import AddProductQty from "./ViewLead/AddProductQty";
import AddProductItems from "./ViewLead/AddProductItems";
import Loader from "../../components/common/general/Loader";

const AddProductDialog = ({
  id,
  setData,
  wilaya_id,
  daira_id,
  delivery_type,
  assign_to
}) => {
  const [productDB, setProductDB] = useState([]);
  const [children, setChildren] = useState(<></>);
  const [loadingCheck, setLoadingCheck] = useState(false);

  const { getLeadProducts, getLeadAddProducts } = useAgentLeads();
  useEffect(() => {
    setProductDB([]);
    if (
      delivery_type !== "" &&
      delivery_type !== null &&
      delivery_type !== undefined &&
      wilaya_id !== "" &&
      wilaya_id !== null &&
      wilaya_id !== undefined &&
      daira_id !== "" &&
      daira_id !== null &&
      daira_id !== undefined
    ) {
      var data = {
        wilaya_id: wilaya_id,
        daira_id: daira_id,
        delivery_type: delivery_type,
        lead_id: id,
        assign_to: assign_to,
      };
      getLeadProducts(setProductDB, data);
    }else{
      setChildren(<></>);
    }
  }, [wilaya_id, daira_id, delivery_type,assign_to]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    unregister,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        product_id: yup
          .string()
          .typeError("Please choose Product")
          .required("Product is required")
      })
    ),
  });
  const HandleProductItem = (e) => {
    if (e.target.value == "") {
      unregister("items");
      unregister("qty");
      setChildren(<></>);
    } else {
      let result = productDB?.filter(function (product) {
        return product.id == e.target.value;
      });
      if (result[0]?.items?.length === 0) {
        unregister("items");
        setChildren(
          <AddProductQty
            register={register}
            errors={errors}
            qty_reste={result[0]?.qty_reste}
          />
        );
      } else {
        unregister("qty");
        setChildren(
          <AddProductItems
            register={register}
            errors={errors}
            items={result[0]?.items}
          />
        );
      }
    }
  };
  const product_id = register("product_id");
  const HandleAdd = (data) => {
    getLeadAddProducts(id, data, setLoadingCheck, setData);
  };
  return (
    <>
      <div
        className="modal fade"
        id="LeadAddProduct"
        tabIndex="-1"
        aria-labelledby="LeadAddProductLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="LeadAddProductLabel">
                Add Products
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="forms-sample" onSubmit={handleSubmit(HandleAdd)}>
                <div className="mb-3">
                  <select
                    name="product_id"
                    id="product_id"
                    className="form-control"
                    {...product_id}
                    onChange={(e) => {
                      product_id.onChange(e);
                      HandleProductItem(e);
                    }}
                  >
                    <option value="">Selectionner</option>
                    {productDB?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {errorMessage(errors, "product_id")}
                </div>
                {children}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  {loadingCheck == true ? (
                    <button className="btn btn-primary">
                      <Loader />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-success">
                      Save changes
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddProductDialog;
