import axiosInstance from "../../Axios/axios";

const getAllLeadsApis = async (page) => {
  return await axiosInstance.get(`store/orders/leads?page=${page}`);
};

const getOrderLeadDetailsApi = async (id) => {
  return await axiosInstance.get(`store/orders/leads/${id}`);
};

const getOrderLeadStatusApi = async (id) => {
  return await axiosInstance.get(`store/orders/leads/${id}/get_status`);
};

const getUpdateOrderLeadStatusApi = async (id, data) => {
  return await axiosInstance.post(
    `store/orders/leads/${id}/status/update`,
    data
  );
};

const getCustomersApi = async (id) => {
  return await axiosInstance.get(`store/orders/leads/${id}/check_user`);
};

const getDeliveryInformationsApi = async (id) => {
  return await axiosInstance.get(`store/orders/leads/${id}/get_delivery`);
};

const getStoreProductsApi = async (id) => {
  return await axiosInstance.get(`store/orders/leads/get_products`);
};

const getConfirmeLeadApi = async (id, data) => {
  return await axiosInstance.post(`store/orders/leads/${id}/confirme`, data);
};

const getUpdateOrderLeadApi = async (id, data) => {
  return await axiosInstance.post(`store/orders/leads/${id}/update`, data);
};

const getDeliveryTypesApi = async () => {
  return await axiosInstance.get(`store/orders/leads/get_delivery_type`);
};
const getLeadStatisticsApi = async () => {
  return await axiosInstance.get(`store/orders/leads/statistics`);
};
const getLeadCheckDeliveryApi = async (id, data) => {
  return await axiosInstance.post(`store/orders/leads/${id}/check_delivery`,data);
};
const getLeadProductsApi = async (data) => {
  return await axiosInstance.post(`store/orders/leads/get_products`,data);
};
export {
  getAllLeadsApis,
  getOrderLeadDetailsApi,
  getOrderLeadStatusApi,
  getUpdateOrderLeadStatusApi,
  getCustomersApi,
  getDeliveryInformationsApi,
  getStoreProductsApi,
  getConfirmeLeadApi,
  getUpdateOrderLeadApi,
  getDeliveryTypesApi,
  getLeadStatisticsApi,
  getLeadCheckDeliveryApi,
  getLeadProductsApi
};
