import { useParams } from "react-router-dom";
import Breadcrumb from "../../../../components/common/Breadcrumb";
import { useEffect, useState } from "react";
import useYalidineHandle from "../../../../hooks/app/DeliveryCompany/yalidine/useYalidineHandle";
import Loader from "../../../../components/common/general/Loader";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import useSearch from "../../../../hooks/useSearch";

const ViewDeliveryCompanyYalidine = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loadingData, setLoadingData] = useState({});
  const [loadingSync, setLoadingSync] = useState(false);
  const { getYalidineInformation, SyncTarifsFunction } = useYalidineHandle();
  useEffect(() => {
    getYalidineInformation(id, setLoadingData, setData);
  }, [id]);
  const SyncTafifs = (e) => {
    SyncTarifsFunction(id, setLoadingSync, setData);
  };
  return (
    <>
      <Breadcrumb
        title="Delivery Company Yalidine"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="View Delivery Company Yalidine"
        secondaryLink="/delivery/company"
        secondarytitle="Delivery Company"
      />
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a
            className="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            href="#home"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            Main Informations
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="profile-tab"
            data-bs-toggle="tab"
            href="#profile"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >
            Statistics
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="contact-tab"
            data-bs-toggle="tab"
            href="#contact"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
          >
            Tarifs
          </a>
        </li>
      </ul>
      <div className="tab-content border border-top-0 p-3" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          Yalidine Informations
        </div>
        <div
          className="tab-pane fade"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          Yalidine Statistics
        </div>
        <div
          className="tab-pane fade"
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <div className="row m-2">
              <div className="alert alert-info" role="alert">
                Please wait 5 minutes after you click synchronize
              </div>
            </div>
          <div className="g-3 row">
            <div className="col-sm-5">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="card-title">Tarifs</div>
              </div>
            </div>
            <div className="col-sm-auto ms-auto">
              <div>
                {loadingSync ? (
                  <Link className="btn btn-primary">
                    <Loader />
                  </Link>
                ) : (
                  <Link
                    className="btn btn-success"
                    onClick={(e) => SyncTafifs(e)}
                  >
                    <FeatherIcon
                      icon="refresh-cw"
                      className="ri-add-line align-bottom me-2"
                    />
                    Synchronize from Yalidine
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Wilaya</th>
                  <th>Is Deliverable</th>
                  <th>Has StopDesk</th>
                  <th>Retour Fee</th>
                  <th>Oversize Fee</th>
                  <th>COD Percentage</th>
                  <th>Insurance Percentage</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {loadingData ? (
                  <tr>
                    <td>
                      <Loader />
                    </td>
                    <td>
                      <Loader />
                    </td>
                    <td>
                      <Loader />
                    </td>
                    <td>
                      <Loader />
                    </td>
                    <td>
                      <Loader />
                    </td>
                    <td>
                      <Loader />
                    </td>
                    <td>
                      <Loader />
                    </td>
                    <td>
                      <Loader />
                    </td>
                  </tr>
                ) : null}
                {data.tarifs?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>
                        {" "}
                        <span
                          className={
                            item.is_deliverable === true
                              ? "badge bg-success"
                              : "badge bg-danger"
                          }
                        >
                          {item?.is_deliverable === true ? "True" : "False"}
                        </span>
                      </td>
                      <td>
                        {" "}
                        <span
                          className={
                            item.has_stop_desk === true
                              ? "badge bg-success"
                              : "badge bg-danger"
                          }
                        >
                          {item?.has_stop_desk === true ? "True" : "False"}
                        </span>
                      </td>
                      <td>{item.retour_fee ?? 0} Da</td>
                      <td>{item.oversize_fee ?? 0} Da</td>
                      <td>{item.cod_percentage ?? 0} %</td>
                      <td>{item.insurance_percentage ?? 0} %</td>
                      <td>
                        <div className="d-flex flex-row justify-between">
                          <Link
                            to={`/delivery/company/view/yalidine/${id}/view/${item.id}`}
                            title="View communes"
                            className="me-3"
                          >
                            <FeatherIcon icon="eye" color="green" size={20} />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewDeliveryCompanyYalidine;
