import { useState, useEffect } from "react";
import useAgentLeads from "../../hooks/agent/Leads/useAgentLeads";
import Loading from "../Loading";
import { Link } from "react-router-dom/dist";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../helpers/errorMessage";

const CreateLeadAgent = () => {
  const { getWilayas, getDairas } = useAgentLeads();
  const [wilayas, setWilayas] = useState([]);
  const [wilayasLoading, setWilayasLoading] = useState(false);
  const [dairas, setdairas] = useState([]);
  const [dairaLoading, setDairaLoading] = useState(false);
  const [DeliveryWarning, setDeliveryWarning] = useState(<></>);
  const [deliveryCost, setDeliveryCost] = useState(0);
  const [deliveryCostAnou, setDeliveryCostAnou] = useState(0);

  useEffect(() => {
    getWilayas(setWilayasLoading, setWilayas);
  }, []);
  const {
    setError,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(ConfirmeLeadSchema),
  });
  const wilaya_id = register("wilaya_id");
  const daira_id = register("daira_id");
  const delivery_type = register("delivery_type");

  const onSubmit = (temp_data) => {
    console.log(temp_data);
  };
  const handleChangeWilaya = (e) => {
    setValue("daira_id", null);
    getDairas(e.target.value, setDairaLoading, setdairas);
  };
  const CheckDelivery = (ev) => {
    const values = getValues();
    if (
      values.delivery_type !== "" &&
      values.delivery_type !== null &&
      values.delivery_type !== undefined &&
      values.wilaya_id !== "" &&
      values.wilaya_id !== null &&
      values.wilaya_id !== undefined &&
      values.daira_id !== "" &&
      values.daira_id !== null &&
      values.daira_id !== undefined
    ) {
      setDeliveryWarning(<></>);
      // getCheckDelivery(
      //   data.lead?.id,
      //   values,
      //   setDeliveryWarning,
      //   setDeliveryCost,
      //   setDeliveryCostAnou
      // );
    } else {
      setDeliveryWarning(<></>);
      setDeliveryCost(0);
      setDeliveryCostAnou(0);
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-8">
          <div className="card">
            <div className="card-header">Add New Lead</div>
            <div className="card-body">
              <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-6 mb-2">
                    <label for="first_name" className="form-label">
                      First name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      autocomplete="off"
                      placeholder="First name"
                      {...register("first_name")}
                    />
                    {errorMessage(errors, "first_name")}
                  </div>
                  <div className="col-lg-6 mb-2">
                    <label for="last_name" className="form-label">
                      Last name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="last_name"
                      autocomplete="off"
                      placeholder="Last name"
                      {...register("last_name")}
                    />
                    {errorMessage(errors, "last_name")}
                  </div>
                  <div className="col-lg-6 mb-2">
                    <label for="phone" className="form-label">
                      Phone
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      autocomplete="off"
                      placeholder="Phone"
                      {...register("phone")}
                    />
                    {errorMessage(errors, "phone")}
                  </div>
                  <div className="col-lg-6 mb-2">
                    <label for="phone_secondary" className="form-label">
                      Phone Secondary
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone_secondary"
                      autocomplete="off"
                      placeholder="Phone Secondary"
                      {...register("phone_secondary")}
                    />
                    {errorMessage(errors, "phone_secondary")}
                  </div>
                  <div className="col-lg-6 mb-2">
                    <label for="address" className="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      autocomplete="off"
                      placeholder="Address"
                      {...register("address")}
                    />
                    {errorMessage(errors, "address")}
                  </div>
                  <div className="col-lg-6 mb-2">
                    <label for="note" className="form-label">
                      Note
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="note"
                      autocomplete="off"
                      placeholder="Note"
                      {...register("note")}
                    />
                    {errorMessage(errors, "note")}
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label for="wilaya_id" className="form-label">
                        Wilaya:
                      </label>
                      <select
                        name="wilaya_id"
                        id="wilaya_id"
                        className="form-control"
                        {...wilaya_id}
                        onChange={(e) => {
                          wilaya_id.onChange(e);
                          handleChangeWilaya(e);
                          // CheckDelivery(e);
                        }}
                      >
                        {wilayasLoading === true ? (
                          <option value="">Loading....</option>
                        ) : (
                          <option value="">Selectionner</option>
                        )}
                        {wilayas?.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      {errorMessage(errors, "wilaya_id")}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label for="daira_id" className="form-label">
                        Dairas:
                      </label>
                      <select
                        name="daira_id"
                        id="daira_id"
                        className="form-control"
                        {...daira_id}
                        onChange={(e) => {
                          daira_id.onChange(e);
                          // CheckDelivery(e);
                        }}
                      >
                        {dairaLoading === true ? (
                          <option value="">Loading....</option>
                        ) : (
                          <option value="">Selectionner</option>
                        )}
                        {dairas?.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      {errorMessage(errors, "daira_id")}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label for="delivery_type" className="form-label">
                        Delivery Type:
                      </label>
                      <select
                        name="delivery_type"
                        id="delivery_type"
                        className="form-control"
                        {...delivery_type}
                        onChange={(e) => {
                          delivery_type.onChange(e);
                          // CheckDelivery(e);
                        }}
                      >
                        <option value="">Selectionner</option>
                        <option value={1}>Home</option>
                        <option value={2}>Stop Desk</option>
                      </select>
                      {errorMessage(errors, "delivery_type")}
                    </div>
                  </div>
                  <div className="col-lg-12">{DeliveryWarning}</div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-4">Add Product</div>
      </div>
    </>
  );
};
export default CreateLeadAgent;
