import Breadcrumb from "../../../components/common/Breadcrumb";
import { useEffect, useState } from "react";
import useSearch from "../../../hooks/useSearch";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Table from "../../../components/table/Table";
import CreateCategoryModal from "./CreateCategoryModal";
import useCreateCategory from "../../../hooks/app/categories/useCreateCategory";

const ShowCategories = () => {
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();

  const [loadingCategories,setloadingCategories] = useState(false);
  const [CategoriesData,setCategoriesData] = useState([]);
  const { GetAllCategories } = useCreateCategory();
  useEffect(() => {
    GetAllCategories(page, setloadingCategories, setCategoriesData);
  }, [page]);
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) => {
        return (
          <img
            className="wd-30 ht-30 rounded-circle"
            src={row?.image?.link}
          ></img>
        );
      },
      sortable: false,
    },
    {
      name: "Name",
      selector: (row) => {
        return (
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.name}
          >
            {row?.name}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => {
        return (
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.description}
          >
            {row?.description}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Products",
      selector: (row) => {
        return <div>{row?.count_products}</div>;
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <span
            className={
              row?.status === true ? "badge bg-success" : "badge bg-danger"
            }
          >
            {row?.status === true ? "Active" : "Inactive"}
          </span>
        );
      },
      sortable: true,
    },
    {
      name: "created At",
      selector: (row) => row?.created_at,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              to={row?.is_primary !== 1 && `/categories/update/${row.id}`}
              title="update category"
              className="me-3"
            >
              <FeatherIcon icon="edit" size={20} />
            </Link>
            <Link title="delete category">
              <FeatherIcon icon="trash" color="red" size={20} />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Breadcrumb
        title="Categories"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Categories"
      />
      <Table
        isLoading={loadingCategories}
        query={query}
        setQuery={setQuery}
        titleLabel="Category"
        columns={columns}
        data={search(
          CategoriesData?.data?.data,
          ["name", "description"],
          query
        )}
        meta={CategoriesData?.data?.meta}
        btnFunction={true}
        setPage={setPage}
        ModalId="AddCategory"
      />
      <CreateCategoryModal modalId="AddCategory" setCategoriesData={setCategoriesData}/>
    </>
  );
};
export default ShowCategories;
