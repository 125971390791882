import { useParams } from "react-router-dom";
import Breadcrumb from "../../../../components/common/Breadcrumb";
import useDeliveryManFunctions from "../../../../hooks/app/DeliveryMan/useDeliveryManFunctions";
import { useEffect, useState } from "react";
import Loader from "../../../../components/common/general/Loader";
import DeliveryManMainInformations from "./UpdateComponents/DeliveryManMainInformations.jsx";
import DeliveryManAddress from "./UpdateComponents/DeliveryManAddress.jsx";
import DeliveryManAddTarifs from "./UpdateComponents/DeliveryManAddTarifs.jsx";
import Loading from "../../../Loading";

const UpdateDeliveryMan = () => {
  const { id } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState({});
  const [wilayas, setWilayas] = useState([]);
  const [wilayasLoading, setWilayasLoading] = useState([]);
  const {
    getWilayas,
    getWilayaDairas,
    getDairas,
    UpdateDeliveryMan,
    getDeliveryMan,
    AddDeliveryAddress,
    AddTarif,
    UpdateTarif,
  } = useDeliveryManFunctions();
  useEffect(() => {
    getDeliveryMan(id, setLoadingData, setData);
    getWilayas(setWilayas, setWilayasLoading);
  }, [id]);

  return (
    <>
      <Breadcrumb
        title="Delivery Man"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Update Delivery man"
        secondaryLink="/delivery/man"
        secondarytitle="Delivery Man"
      />
      {loadingData ? (
        <Loading />
      ) : (
        <>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Main Informations
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Address
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="contact-tab"
                data-bs-toggle="tab"
                href="#contact"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Tarifs
              </a>
            </li>
          </ul>
          <div
            className="tab-content border border-top-0 p-3"
            id="myTabContent"
          >
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <DeliveryManMainInformations
                UpdateDeliveryMan={UpdateDeliveryMan}
                dataMan={data}
              />
            </div>
            <div
              className="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <DeliveryManAddress
                UpdateDeliveryMan={UpdateDeliveryMan}
                dataMan={data}
                wilayas={wilayas}
                getWilayas={getWilayas}
                getWilayaDairas={getWilayaDairas}
                AddDeliveryAddress={AddDeliveryAddress}
              />
            </div>
            <div
              className="tab-pane fade"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <DeliveryManAddTarifs
                UpdateDeliveryMan={UpdateDeliveryMan}
                dataMan={data}
                getWilayas={getWilayas}
                UpdateTarif={UpdateTarif}
                getWilayaDairas={getDairas}
                AddTarif={AddTarif}
                setData={setData}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default UpdateDeliveryMan;
