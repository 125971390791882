import { useNavigate } from "react-router-dom";
import {
  getCreateSourceApi,
  getSourceDetailsApi,
  getSourceUpdateApi,
  getAllSourceApi
} from "../../../apis/app/order_source/getOrderSourceApis";
import { handleErrorApi } from "../../../helpers/handleErrorApi";
import Toast from "../../../helpers/toast";

const useSourceFunctions = () => {
  const navigate = useNavigate();
  const CreateSource = async (data, setLoading,setDatas) => {
    try {
      setLoading(true);
      const response = await getCreateSourceApi(data);
      setDatas(response);
      Toast("success", "Source was created successfully");
      navigate("/orders/sources");
    } catch (error) {
      setLoading(false);
      // handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const getSourceDetails = async (id, setLoading, setData) => {
    try {
      setLoading(true);
      const response = await getSourceDetailsApi(id);
      setData(response?.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const getUpdateSource = async (id, data, setLoading) => {
    try {
      setLoading(true);
      const response = await getSourceUpdateApi(id,data);
      Toast("success", "Source was updated successfully");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const GetAllSources = async (page,setLoading,setDatas) =>{
    try {
      setLoading(true);
      const response = await getAllSourceApi(page);
      setDatas(response);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.message,error?.response.data.error);
    } finally {
      setLoading(false);
    }
  }
  return {
    CreateSource,
    getSourceDetails,
    getUpdateSource,
    GetAllSources
  };
};
export default useSourceFunctions;
