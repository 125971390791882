import axiosInstance from "../../Axios/axios";

const OrderSettingsApi = async () => {
  return await axiosInstance.get(`store/store/get_settings`);
};

const UpdateOrderSettingsApi = async (data) => {
  return await axiosInstance.post(`store/store/update_settings`,data);
};

export {
    OrderSettingsApi,
    UpdateOrderSettingsApi
};
