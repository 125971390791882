import Breadcrumb from "../../../components/common/Breadcrumb";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useState, useEffect } from "react";
import useOrderSettingsFunctions from "../../../hooks/app/orderSetttings/useOrderSettingsFunctions";
import { useForm } from "react-hook-form";
import Loader from "../../../components/common/general/Loader";
import InputComponent from "../../../components/FormUpdate/InputComponent";
import SelectComponent from "../../../components/FormUpdate/SelectComponent";
import Toast from "../../../helpers/toast.js";
import Loading from "../../Loading.jsx";

const OrderSettings = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { getOrderSettings,updateOrderSettings } = useOrderSettingsFunctions();
  useEffect(() => {
    getOrderSettings(setLoadingData, setData);
  }, []);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: data,
  });
  const onSubmit = (data) => {
    if (
      data.after_failed_one === undefined &&
      data.after_failed_two === undefined && 
      data.after_failed_three === undefined && 
      data.failed_three_to_other_agent === undefined && 
      data.cancel_to_other_agent === undefined
    ) {
      Toast("error", "Nothing to Update");
    } else {
      updateOrderSettings(data,setLoading,setData);
    }
  };
  return (
    <>
      <Breadcrumb
        title="Order Settings"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Order Settings"
      />
      {loadingData ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-4">
                    <InputComponent
                      errors={errors}
                      inputType="numeric"
                      label="Time After Failed one"
                      name="after_failed_one"
                      placeholder="Time After Failed one"
                      control={control}
                      defaultValue={data?.after_failed_one}
                      error="after_failed_one"
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputComponent
                      errors={errors}
                      inputType="numeric"
                      label="Time After Failed two"
                      name="after_failed_two"
                      placeholder="Time After Failed two"
                      control={control}
                      defaultValue={data?.after_failed_two}
                      error="after_failed_two"
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputComponent
                      errors={errors}
                      inputType="numeric"
                      label="Time After Failed three"
                      name="after_failed_three"
                      placeholder="Time After Failed three"
                      control={control}
                      defaultValue={data?.after_failed_three}
                      error="after_failed_two"
                    />
                  </div>
                  <div className="col-lg-6">
                    <SelectComponent
                      data={[
                        {
                          value: 1,
                          label: "Active",
                        },
                        {
                          value: 0,
                          label: "Inactive",
                        },
                      ]}
                      defaultData={data?.failed_three_to_other_agent}
                      control={control}
                      name="failed_three_to_other_agent"
                      closeMenuOnSelect
                      label="Dispatch to other agent after Failed Three"
                    />
                  </div>
                  <div className="col-lg-6">
                    <SelectComponent
                      data={[
                        {
                          value: 1,
                          label: "Active",
                        },
                        {
                          value: 0,
                          label: "Inactive",
                        },
                      ]}
                      defaultData={data?.cancel_to_other_agent}
                      control={control}
                      name="cancel_to_other_agent"
                      closeMenuOnSelect
                      label="Dispatch to other agent after Cancel"
                    />
                  </div>
                </div>
                {loading ? (
                  <button type="submit" className="btn btn-primary mt-2">
                    <Loader />
                  </button>
                ) : (
                  <button type="submit" className="btn btn-success mt-2">
                    Update Settings
                  </button>
                )}
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default OrderSettings;
