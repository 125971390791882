import { useParams } from "react-router-dom";
import Breadcrumb from "../../../../../components/common/Breadcrumb";
import { useState } from "react";
import useSearch from "../../../../../hooks/useSearch";
import { useYalidine } from "../../../../../hooks/app/DeliveryCompany/yalidine/useYalidine";
import Table from "../../../../../components/table/Table";

const YalidineTarifCommunes = () => {
  const { id } = useParams();
  const { wilaya_id } = useParams();
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();
  const { Datas, loadingDatas } = useYalidine(page, id, wilaya_id);
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Commune",
      selector: (row) => row.commune_name,
      sortable: true,
    },
    {
      name: "Price Home",
      selector: (row) => row.express_home + " Da",
      sortable: true,
    },
    {
      name: "Price Desk",
      selector: (row) => row.express_desk + " Da",
      sortable: true,
    },
    {
      name: "Commune System",
      selector: (row) => (row.commune_system ? <><span className="text-success">{row.commune_system.name}</span></>  : <></>),
    },
    // {
    //   name: "Actions",
    //   selector: (row) => {
    //     return (
    //       <div className="d-flex flex-row justify-between">
    //         <Link
    //           to={`/factures/update/${row.id}`}
    //           title="update facture"
    //           className="me-3"
    //         >
    //           <FeatherIcon icon="edit" size={20} />
    //         </Link>
    //         <Link title="delete facture">
    //           <FeatherIcon icon="trash" color="red" size={20} />
    //         </Link>
    //       </div>
    //     );
    //   },
    // },
  ];
  return (
    <>
      <Breadcrumb
        title="Yalidine Tarifs Communes"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Yalidine Tarifs Communes"
        secondaryLink={`/delivery/company/view/yalidine/${id}`}
        secondarytitle="Delivery Company"
      />
      <Table
        isLoading={loadingDatas}
        query={query}
        setQuery={setQuery}
        columns={columns}
        data={search(Datas?.data?.data, ["name"], query)}
        meta={Datas?.data?.meta}
        setPage={setPage}
        btnFunction={false}
        titleLabel={null}
      />
    </>
  );
};
export default YalidineTarifCommunes;
