import axiosInstance from "../../Axios/axios";

const CreateCategoryApi = async (data) => {
  return await axiosInstance.post(`store/categories`, data);
};

const GetAllCategoriesApi = async (page) => {
  return await axiosInstance.get(`store/categories?page=${page}`);
};

export { CreateCategoryApi, GetAllCategoriesApi };
