import Breadcrumb from "../../../components/common/Breadcrumb";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import useSearch from "../../../hooks/useSearch";
import { useEffect, useState } from "react";
import Table from "../../../components/table/Table";
import { useGetAllSource } from "../../../hooks/app/orderSource/useGetAllSource";
import CreateOrderSourceDialog from "./CreateOrderSourceDialog";
import CreateSourceModal from "./CreateSourceModal";
import useSourceFunctions from "../../../hooks/app/orderSource/useSourceFunctions";

const OrderSource = () => {
  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();

  const [loadingData, setloadingData] = useState(false);
  const [Datas, setDatas] = useState([]);
  const { GetAllSources } = useSourceFunctions();

  useEffect(() => {
    GetAllSources(page, setloadingData, setDatas);
  }, [page]);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <span
            className={
              row?.status === true ? "badge bg-success" : "badge bg-danger"
            }
          >
            {row?.status === true ? "Active" : "Inactive"}
          </span>
        );
      },
    },
    {
      name: "created at",
      selector: (row) => row?.created_at,
      sortable: true,
    },
    {
      name: "updated at",
      selector: (row) => row?.updated_at,
      sortable: false,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              to={`/orders/sources/update/${row.id}`}
              title="update source"
              className="me-3"
            >
              <FeatherIcon icon="edit" size={20} />
            </Link>
            <Link title="delete source">
              <FeatherIcon icon="trash" color="red" size={20} />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Breadcrumb
        title="Order Sources"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Order Sources"
      />
      <Table
        isLoading={loadingData}
        query={query}
        setQuery={setQuery}
        titleLabel="Sources"
        columns={columns}
        data={search(Datas?.data?.data, ["name"], query)}
        meta={Datas?.data?.meta}
        setPage={setPage}
        btnFunction={true}
        ModalId="CreateSource"
      />
      <CreateSourceModal
        modalId="CreateSource"
        setDatas={setDatas}
      />
    </>
  );
};
export default OrderSource;
