import { handleErrorApi } from "../../../helpers/handleErrorApi";
import { CreateCategoryApi,GetAllCategoriesApi } from "../../../apis/app/categories/CreateCategoryApi";
import { GetCategoryApi } from "../../../apis/app/categories/GetCategoryApi";
import { UpdateCategoryApi } from "../../../apis/app/categories/UpdateCategoryApi";
import { useNavigate } from "react-router-dom";
import Toast from "../../../helpers/toast.js";

const useCreateCategory = () => {
    const navigate = useNavigate();
    const GetAllCategories = async (page, setLoading, setCategoriesData)=>{
      try {
        setLoading(true);
        const response = await GetAllCategoriesApi(page);
        setCategoriesData(response);
      } catch (error) {
        setLoading(false);
        handleErrorApi(error?.response.status, error?.response.data.message,error?.response.data.error);
      } finally {
        setLoading(false);
      }
    }
    const CreateCategory = async (data, setLoading,modalId,setCategoriesData) => {
        try {
            setLoading(true);
            const response = await CreateCategoryApi(data);
            setCategoriesData(response);
            Toast("success", "You have successfully create your category");
          } catch (error) {
            setLoading(false);
            handleErrorApi(error?.response.status, error?.response.data.message,error?.response.data.error);
          } finally {
            const element = document.getElementById(modalId);
            element.classList.remove("show");
            element.style.display = "none";
            setLoading(false);
          }
    }
    const GetCategory = async (id,setLoading,setData) =>{
      try{
        setLoading(true);
        const response = await GetCategoryApi(id);
        setData(response?.data?.data);
      }catch (error) {
        setLoading(false);
        handleErrorApi(error?.response.status, error?.response.data.message,error?.response.data.error);
      } finally {
        setLoading(false);
      }
    }
    const UpdateCategory = async(data,setLoading,id,setData)=>{
      try{
        setLoading(true);
        const response = await UpdateCategoryApi(id,data);
        setData(response?.data?.data);
        navigate("/categories");
      }catch (error) {
        setLoading(false);
        handleErrorApi(error?.response.status, error?.response.data.message,error?.response.data.error);
      } finally {
        setLoading(false);
      }
    }
    return {
        CreateCategory,
        GetCategory,
        UpdateCategory,
        GetAllCategories
    };
}
export default useCreateCategory;