import { errorMessage } from "../../../../helpers/errorMessage";

const ViewLeadAssignToComponent = ({assign_to,CheckDelivery,errors}) => {
  return (
    <>
      <div className="col-lg-3">
        <label for="assign_to" className="form-label">
          Assing To:
        </label>
        <select
          name="assign_to"
          id="assign_to"
          className="form-control"
          {...assign_to}
          onChange={(e) => {
            assign_to.onChange(e);
            CheckDelivery(e);
          }}
        >
          <option value={1}>Automatique</option>
          <option value={2}>Delivery Man</option>
          <option value={3}>Delivery Company</option>
        </select>
        {errorMessage(errors, "assign_to")}
      </div>
    </>
  );
};
export default ViewLeadAssignToComponent;
