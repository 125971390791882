import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/common/Breadcrumb";
import { useState, useEffect } from "react";
import useProductFunctions from "../../../hooks/app/products/useProductFunctions";
import { useForm } from "react-hook-form";
import InputComponent from "../../../components/FormUpdate/InputComponent";
import SelectComponent from "../../../components/FormUpdate/SelectComponent";
import TextareaComponent from "../../../components/FormUpdate/TextareaComponent";
import ImageDropzone from "../../../components/common/general/ImageDropzone";
import Toast from "../../../helpers/toast.js";
import Loader from "../../../components/common/general/Loader";
import ImageCard from "../../../components/common/general/ImageCard.jsx";
import { Link } from "react-router-dom/dist";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Loading from "../../Loading.jsx";
import AddProductItemDialog from "./UpdateProduct/AddProductItemDialog.jsx";
import AllWilayaUpdate from "./UpdateProduct/AllWilayaUpdate.jsx";
import SomeWilayaUpdate from "./UpdateProduct/SomeWilayaUpdate.jsx";
import { errorMessage } from "../../../helpers/errorMessage";

const UpdateProduct = () => {
  const { id } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [Categories, setCategories] = useState([]);
  const [loadingPicture, setLoadingPicture] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);
  const [children, setChildren] = useState(<></>);
  const [loadingDiscount, setLoadingDiscount] = useState(false);
  const [wilayas, setWilayas] = useState([]);
  const [loadingwilayas, setLoadingWilayas] = useState([]);

  const {
    getCategories,
    getProductDetails,
    DeletePicture,
    UpdateProductfunction,
    UpdateProductItemFunction,
    AddProductPicturesFunction,
    UpdateProductDiscountFunction,
    getWilayas
  } = useProductFunctions();
  useEffect(() => {
    getProductDetails(id, setLoadingData, setData);
    getCategories(setCategories);
    getWilayas(setLoadingWilayas, setWilayas);
  }, [id]);
  useEffect(() => {
    CheckDeliveryDiscount(data?.discount_delivery);
  }, [data]);
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: data,
  });
  const {
    setValue: setValueImages,
    handleSubmit: handleImages,
    formState: { errorsImages },
    watch: watchImages,
  } = useForm({});
  const photo = watchImages("images");

  const {
    register: registerDiscount,
    setValue: setValueDiscount,
    handleSubmit: handleDiscount,
    control: controlDiscount,
    formState: { errorsDiscount },
    unregister: unregisterDiscount
  } = useForm({});
  const onSubmit = (data) => {
    UpdateProductfunction(id, { ...data }, setLoading, setError, setData);
  };
  const DeleteImage = (id) => {
    let product_id = data?.id;
    DeletePicture(id, product_id, setLoadingPicture, setData);
  };
  const DeleteItem = (index) => {
    Toast("error", "This function in not working");
  };
  const UpdateItem = (e, item_id, att) => {
    if (e.keyCode === 13) {
      let temp_data;
      e.preventDefault();
      switch (att) {
        case "name":
          temp_data = { name: e.currentTarget.textContent };
          break;
        case "price":
          temp_data = { price: e.currentTarget.textContent };
          break;
      }
      UpdateProductItemFunction(
        setLoadingUpdate,
        id,
        item_id,
        temp_data,
        setData
      );
    }
  };
  const onSubmitImages = (data_images) => {
    if (Object.hasOwn(data_images, "images")) {
      if (data_images.images.length !== 0) {
        AddProductPicturesFunction(
          setLoadingImages,
          id,
          data_images,
          setData,
          setValueImages
        );
      } else {
        Toast("error", "Please Choose pictures first");
      }
    } else {
      Toast("error", "Please Choose pictures first");
    }
  };
  const CheckDeliveryDiscount = (delivery) => {
    switch (delivery) {
      case "no_discount":
        setChildren(<></>);
        break;
      case "all_wilayas":
        setChildren(
          <AllWilayaUpdate
            defaultValue={data?.discount}
            register={registerDiscount}
            new_data = {false}
          />
        );
        break;
      case "some_wilayas":
        setChildren(
          <SomeWilayaUpdate
            items={data?.discount_wilayas}
            register={registerDiscount}
          />
        );
        break;
      default:
        setChildren(<></>);
        break;
    }
  };
  const onSubmitDiscount = (data_discount) => {
    UpdateProductDiscountFunction(setLoadingDiscount,id,data_discount,setData)
  };
  const HandleDeliveryDiscount = (e) => {
    switch (e.target.value) {
      case "no_discount":
        unregisterDiscount('wilaya_1');
        unregisterDiscount('wilaya_2');
        unregisterDiscount('wilaya_3');
        unregisterDiscount('wilaya_4');
        unregisterDiscount('wilaya_5');
        unregisterDiscount('wilaya_6');
        unregisterDiscount('wilaya_7');
        unregisterDiscount('wilaya_8');
        unregisterDiscount('wilaya_9');
        unregisterDiscount('wilaya_10');
        unregisterDiscount('wilaya_11');
        unregisterDiscount('wilaya_12');
        unregisterDiscount('wilaya_13');
        unregisterDiscount('wilaya_14');
        unregisterDiscount('wilaya_15');
        unregisterDiscount('wilaya_16');
        unregisterDiscount('wilaya_17');
        unregisterDiscount('wilaya_18');
        unregisterDiscount('wilaya_19');
        unregisterDiscount('wilaya_20');
        unregisterDiscount('wilaya_21');
        unregisterDiscount('wilaya_22');
        unregisterDiscount('wilaya_23');
        unregisterDiscount('wilaya_24');
        unregisterDiscount('wilaya_25');
        unregisterDiscount('wilaya_26');
        unregisterDiscount('wilaya_27');
        unregisterDiscount('wilaya_28');
        unregisterDiscount('wilaya_29');
        unregisterDiscount('wilaya_30');
        unregisterDiscount('wilaya_31');
        unregisterDiscount('wilaya_32');
        unregisterDiscount('wilaya_33');
        unregisterDiscount('wilaya_34');
        unregisterDiscount('wilaya_35');
        unregisterDiscount('wilaya_36');
        unregisterDiscount('wilaya_37');
        unregisterDiscount('wilaya_38');
        unregisterDiscount('wilaya_39');
        unregisterDiscount('wilaya_40');
        unregisterDiscount('wilaya_41');
        unregisterDiscount('wilaya_42');
        unregisterDiscount('wilaya_43');
        unregisterDiscount('wilaya_44');
        unregisterDiscount('wilaya_45');
        unregisterDiscount('wilaya_46');
        unregisterDiscount('wilaya_47');
        unregisterDiscount('wilaya_48');
        unregisterDiscount('wilaya_49');
        unregisterDiscount('wilaya_50');
        unregisterDiscount('wilaya_51');
        unregisterDiscount('wilaya_52');
        unregisterDiscount('wilaya_53');
        unregisterDiscount('wilaya_54');
        unregisterDiscount('wilaya_55');
        unregisterDiscount('wilaya_56');
        unregisterDiscount('wilaya_57');
        unregisterDiscount('wilaya_58');
        unregisterDiscount('discount_amount');
        setChildren(<></>);
        break;
      case "all_wilayas":
        unregisterDiscount('wilaya_1');
        unregisterDiscount('wilaya_2');
        unregisterDiscount('wilaya_3');
        unregisterDiscount('wilaya_4');
        unregisterDiscount('wilaya_5');
        unregisterDiscount('wilaya_6');
        unregisterDiscount('wilaya_7');
        unregisterDiscount('wilaya_8');
        unregisterDiscount('wilaya_9');
        unregisterDiscount('wilaya_10');
        unregisterDiscount('wilaya_11');
        unregisterDiscount('wilaya_12');
        unregisterDiscount('wilaya_13');
        unregisterDiscount('wilaya_14');
        unregisterDiscount('wilaya_15');
        unregisterDiscount('wilaya_16');
        unregisterDiscount('wilaya_17');
        unregisterDiscount('wilaya_18');
        unregisterDiscount('wilaya_19');
        unregisterDiscount('wilaya_20');
        unregisterDiscount('wilaya_21');
        unregisterDiscount('wilaya_22');
        unregisterDiscount('wilaya_23');
        unregisterDiscount('wilaya_24');
        unregisterDiscount('wilaya_25');
        unregisterDiscount('wilaya_26');
        unregisterDiscount('wilaya_27');
        unregisterDiscount('wilaya_28');
        unregisterDiscount('wilaya_29');
        unregisterDiscount('wilaya_30');
        unregisterDiscount('wilaya_31');
        unregisterDiscount('wilaya_32');
        unregisterDiscount('wilaya_33');
        unregisterDiscount('wilaya_34');
        unregisterDiscount('wilaya_35');
        unregisterDiscount('wilaya_36');
        unregisterDiscount('wilaya_37');
        unregisterDiscount('wilaya_38');
        unregisterDiscount('wilaya_39');
        unregisterDiscount('wilaya_40');
        unregisterDiscount('wilaya_41');
        unregisterDiscount('wilaya_42');
        unregisterDiscount('wilaya_43');
        unregisterDiscount('wilaya_44');
        unregisterDiscount('wilaya_45');
        unregisterDiscount('wilaya_46');
        unregisterDiscount('wilaya_47');
        unregisterDiscount('wilaya_48');
        unregisterDiscount('wilaya_49');
        unregisterDiscount('wilaya_50');
        unregisterDiscount('wilaya_51');
        unregisterDiscount('wilaya_52');
        unregisterDiscount('wilaya_53');
        unregisterDiscount('wilaya_54');
        unregisterDiscount('wilaya_55');
        unregisterDiscount('wilaya_56');
        unregisterDiscount('wilaya_57');
        unregisterDiscount('wilaya_58');
        setChildren(
          <AllWilayaUpdate
            defaultValue={data?.discount}
            register={registerDiscount}
          />
        );
        break;
      case "some_wilayas":
        unregisterDiscount('discount_amount');
        setChildren(
          <SomeWilayaUpdate
            items={data?.discount_wilayas}
            register={registerDiscount}
            new_data = {true}
            wilayas={wilayas}
          />
        );
        break;
      default:
        unregisterDiscount('discount_amount');
        setChildren(<></>);
        break;
    }
  };
  const discount_delivery = registerDiscount("discount_delivery");

  return (
    <>
      <Breadcrumb
        title="Products"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Update Product"
        secondaryLink="/products"
        secondarytitle="Products"
      />
      {loadingData ? (
        <Loading />
      ) : (
        <>
          <div className="row">
            <div className="col-lg-7">
              <div className="card">
                <div className="card-header">Product Informations</div>
                <div className="card-body">
                  <form
                    key={0}
                    className="forms-sample"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row">
                      <div
                        className={
                          data.items?.length === 0 ? "col-lg-3" : "col-lg-4"
                        }
                      >
                        <InputComponent
                          errors={errors}
                          inputType="text"
                          label="Product Name"
                          name="name"
                          placeholder="Product name"
                          control={control}
                          defaultValue={data?.name}
                          error="name"
                        />
                      </div>
                      <div
                        className={
                          data.items?.length === 0 ? "col-lg-3" : "col-lg-4"
                        }
                      >
                        <SelectComponent
                          data={[
                            {
                              value: 1,
                              label: "Active",
                            },
                            {
                              value: 0,
                              label: "Inactive",
                            },
                          ]}
                          defaultData={data?.status}
                          control={control}
                          name="status"
                          closeMenuOnSelect
                          label="Status"
                        />
                      </div>
                      {data.items?.length === 0 ? (
                        <div className="col-lg-3">
                          <InputComponent
                            errors={errors}
                            inputType="number"
                            label="Product Price"
                            name="price"
                            placeholder="Product price"
                            control={control}
                            defaultValue={data?.price}
                            error="price"
                          />
                        </div>
                      ) : null}
                      <div
                        className={
                          data.items?.length === 0 ? "col-lg-3" : "col-lg-4"
                        }
                      >
                        {" "}
                        <SelectComponent
                          data={Categories}
                          defaultData={data?.category?.id}
                          name="cat_id"
                          control={control}
                          closeMenuOnSelect
                          label="Category"
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextareaComponent
                          defaultValue={data?.description}
                          name="description"
                          control={control}
                          error="description"
                          errors={errors}
                          label="Description"
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextareaComponent
                          defaultValue={data?.note}
                          name="note"
                          control={control}
                          error="note"
                          errors={errors}
                          label="Note"
                        />
                      </div>
                    </div>
                    {loading ? (
                      <button type="submit" className="btn btn-primary mt-2">
                        <Loader />
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-success mt-2">
                        Update
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="card">
                <div className="card-header">
                  <div className="g-3 row">
                    <div className="col-sm-5">Product Variants</div>
                    <div className="col-sm-auto ms-auto">
                      <div>
                        <Link
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#AddProductItem"
                        >
                          Add Variant
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {data.items?.length === 0 ? (
                    <div className="alert alert-danger" role="alert">
                      No Items Found
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.items?.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <th>
                                    {loadingUpdate === true ? (
                                      <Loader />
                                    ) : (
                                      <div
                                        contenteditable="true"
                                        onKeyDown={(e) =>
                                          UpdateItem(e, item.id, "name")
                                        }
                                      >
                                        {item.name}
                                      </div>
                                    )}
                                  </th>
                                  <th>
                                    {loadingUpdate === true ? (
                                      <Loader />
                                    ) : (
                                      <div>
                                        <span
                                          contenteditable="true"
                                          onKeyDown={(e) =>
                                            UpdateItem(e, item.id, "price")
                                          }
                                        >
                                          {item.price}
                                        </span>
                                        Da
                                      </div>
                                    )}
                                  </th>
                                  <th>
                                    <div className="d-flex flex-row justify-between">
                                      <Link onClick={(e) => DeleteItem(index)}>
                                        <FeatherIcon
                                          icon="trash"
                                          color="red"
                                          size={20}
                                        />
                                      </Link>
                                    </div>
                                  </th>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <AddProductItemDialog setData={setData} id={id} />
          </div>
          <div className="row mt-2">
            <div className="col-lg-7">
              <div className="card">
                <div className="card-header">Product Pictures</div>
                <div className="card-body">
                  <form
                    key={1}
                    className="forms-sample"
                    onSubmit={handleImages(onSubmitImages)}
                  >
                    <div className="row">
                      {data?.image?.length != 0 ? (
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Product Pictures
                            </label>
                            <div className="row">
                              {data?.image?.map((item, index) => {
                                return (
                                  <div className="col-lg-2" key={index}>
                                    {loadingPicture ? (
                                      <></>
                                    ) : (
                                      <ImageCard
                                        file={item}
                                        key={index}
                                        onCLick={DeleteImage}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="alert alert-warning" role="alert">
                          No Pictures Found
                        </div>
                      )}
                      <div className="col-lg-12 mb-2">
                        <div className="form-group">
                          <ImageDropzone
                            placeholder="Drag and drop or click to select project image"
                            photos={photo}
                            name="images"
                            setValue={setValueImages}
                            multiple={true}
                            previewCol={12}
                            errors={errorsImages}
                            label="Select New Images"
                          />
                        </div>
                      </div>
                    </div>
                    {loadingImages ? (
                      <button type="submit" className="btn btn-primary me-2">
                        <Loader />
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-success me-2">
                        Add Pictures
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="card">
                <div className="card-header">Discount</div>
                <div className="card-body">
                  <form
                    key={2}
                    className="forms-sample"
                    onSubmit={handleDiscount(onSubmitDiscount)}
                  >
                    <div className="row">
                      <div className="col-lg-12 mb-2">
                        <div className="mb-3">
                          <label for="discount_delivery" className="form-label">
                            Delivery Discount
                          </label>
                          <select
                            name="discount_delivery"
                            id="discount_delivery"
                            className="form-control"
                            {...discount_delivery}
                            onChange={(e) => {
                              discount_delivery.onChange(e);
                              HandleDeliveryDiscount(e);
                            }}
                          >
                            <option value="" selected>
                              Selectionner
                            </option>
                            <option value="no_discount">No Discount</option>
                            <option value="all_wilayas">All Wilayas</option>
                            <option value="some_wilayas">Some Wilayas</option>
                          </select>
                          {errorMessage(errors, "discount_delivery")}
                        </div>
                        {/* <SelectComponent
                          data={[
                            {
                              value: "no_discount",
                              label: "No Discount",
                            },
                            {
                              value: "all_wilayas",
                              label: "All Wilayas",
                            },
                            {
                              value: "some_wilayas",
                              label: "Some Wilayas",
                            },
                          ]}
                          defaultData={data?.discount_delivery}
                          control={controlDiscount}
                          name="discount_delivery"
                          closeMenuOnSelect
                          label="Discount"
                        /> */}
                      </div>
                      <div className="col-lg-12">{children}</div>
                    </div>
                    {loadingDiscount ? (
                      <button type="submit" className="btn btn-primary mt-2">
                        <Loader />
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-success mt-2">
                        Save
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default UpdateProduct;
