import axiosInstance from "../Axios/axios";

const RegisterApi = async (data) => {
  return await axiosInstance.post(`store/auth/register`, data);
};

const checkDataApi = async (data) => {
  return await axiosInstance.post(`store/auth/check_data`, data);
};

export { RegisterApi,checkDataApi };
