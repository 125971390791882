import { handleErrorApi } from "../../../helpers/handleErrorApi";
import {
  getProductCategoriesApi,
  createProductApi,
  getProductDetailsApi,
  deletePictureProductApi,
  updateProductApi,
  getUpdateProductItemApi,
  getCreateProductItemApi,
  getProductPictureCreateApi,
  getUpdateProductDiscountApi
} from "../../../apis/app/products/getProductsApi";
import Toast from "../../../helpers/toast.js";
import { useNavigate } from "react-router-dom";
import { CreateProductSetErrors } from "../../../helpers/HandleSetErrors/CreateProductSetErrors.js";
import { UpdateProductSetErrors } from "../../../helpers/HandleSetErrors/UpdateProductSetErrors";
import { getWilayasApi } from "../../../apis/agent/getAgentLeadsApis";
const useProductFunctions = () => {
  const navigate = useNavigate();

  const getCategories = async (setCategories) => {
    try {
      const response = await getProductCategoriesApi();
      setCategories(response?.data?.data);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
    }
  };
  const getProductDetails = async (id, setLoading, setData) => {
    try {
      setLoading(true);
      const response = await getProductDetailsApi(id);
      setData(response?.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const createProduct = async (data, setLoading, setError) => {
    try {
      setLoading(true);
      const response = await createProductApi(data);
      Toast("success", "You have successfully Create product");
      navigate("/products");
    } catch (error) {
      setLoading(false);
      if (error?.response.status === 400) {
        const data = error?.response.data.error;
        CreateProductSetErrors(data, setError);
      } else {
        handleErrorApi(
          error?.response.status,
          error?.response.data.message,
          error?.response.data.error
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const DeletePicture = async (id, product_id, setLoading, setData) => {
    try {
      setLoading(true);
      const response = await deletePictureProductApi(id, product_id);
      setData(response?.data?.data);
      Toast("success", "Picture was deleted");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const UpdateProductfunction = async (
    id,
    data,
    setLoading,
    setError,
    setData
  ) => {
    try {
      setLoading(true);
      const response = await updateProductApi(id, data);
      Toast("success", "You have successfully Update Product");
      setData(response?.data?.data);
    } catch (error) {
      setLoading(false);
      if (error?.response.status === 400) {
        const data = error?.response.data.error;
        UpdateProductSetErrors(data, setError);
      } else {
        handleErrorApi(
          error?.response.status,
          error?.response.data.message,
          error?.response.data.error
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const getWilayas = async (setLoading, setData) => {
    try {
      setLoading(true);
      const response = await getWilayasApi();
      setData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const UpdateProductItemFunction = async (
    setLoading,
    id,
    item_id,
    data,
    setData
  ) => {
    try {
      setLoading(true);
      const response = await getUpdateProductItemApi(id, item_id, data);
      setData(response?.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const CreateProductItemFunction = async (
    setLoading,
    id,
    data,
    setData,
    setValue
  ) => {
    try {
      setLoading(true);
      const response = await getCreateProductItemApi(id, data);
      setData(response?.data?.data);
      setValue('name',null);
      setValue('price',0);
      Toast('success',"Product variant was created");
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const AddProductPicturesFunction = async (
    setLoading,
    id,
    data,
    setData,
    setValue
  ) => {
    try {
      setLoading(true);
      const response = await getProductPictureCreateApi(id, data);
      setData(response?.data?.data);
      setValue("images",[]);
      Toast('success',"Product picture was added");
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response?.status,
        error?.response?.data?.message,
        error?.response?.data?.error
      );
    } finally {
      setLoading(false);
    }
  };
  const UpdateProductDiscountFunction = async (
    setLoading,
    id,
    data,
    setData
  ) => {
    try {
      setLoading(true);
      const response = await getUpdateProductDiscountApi(id, data);
      // setData(response?.data?.data);
      Toast('success',"Discount Updated Successufly");
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response?.status,
        error?.response?.data?.message,
        error?.response?.data?.error
      );
    } finally {
      setLoading(false);
    }
  };
  return {
    getCategories,
    createProduct,
    getProductDetails,
    DeletePicture,
    UpdateProductfunction,
    getWilayas,
    UpdateProductItemFunction,
    CreateProductItemFunction,
    AddProductPicturesFunction,
    UpdateProductDiscountFunction
  };
};
export default useProductFunctions;
