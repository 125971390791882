import { errorMessage } from "../../../helpers/errorMessage";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateCategorySchema } from "../../../validation/app/categories/CreateCategorySchema";
import useCreateCategory from "../../../hooks/app/categories/useCreateCategory";
import ImageDropzone from "../../../components/common/general/ImageDropzone";
import Loader from "../../../components/common/general/Loader";

const CreateCategoryModal = ({ modalId,setCategoriesData }) => {
  const [loading, setLoading] = useState(false);
  const { CreateCategory } = useCreateCategory();
 
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(CreateCategorySchema),
    mode: "onChange",
  });
  const photo = watch("image");
  const HandleClose = () => {
    const element = document.getElementById(modalId);
    element.classList.remove("show");
    element.style.display = "none";
  }; 
  const onSubmit = (data) => {
    CreateCategory(
      {
        ...data,
      },
      setLoading,
      modalId,
      setCategoriesData
    );
  };
  return (
    <>
      <div className="modal fade" tabIndex="-1" id={modalId}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New Category</h5>
              <button
                type="button"
                className="btn-close"
                onClick={(e) => HandleClose(e)}
              ></button>
            </div>
            <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6">
                    <label for="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      autocomplete="off"
                      placeholder="Category name"
                      {...register("name")}
                    />
                    {errorMessage(errors, "name")}
                  </div>
                  <div className="col-lg-6">
                    <label for="status" className="form-label">
                      Status
                    </label>
                    <select
                      name="status"
                      id="status"
                      className="form-control"
                      {...register("status")}
                    >
                      <option value="">Selectionner</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                    {errorMessage(errors, "status")}
                  </div>
                </div>

                <label for="description" className="form-label">
                  Description
                </label>
                <textarea
                  rows={4}
                  className="form-control"
                  id="name"
                  placeholder="Category Description"
                  {...register("description")}
                />
                {errorMessage(errors, "description")}
                <div className="form-group mt-1">
                  <ImageDropzone
                    placeholder="Drag and drop or click to select image"
                    photos={photo}
                    name="image"
                    setValue={setValue}
                    multiple={false}
                    previewCol={5}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => HandleClose()}
                >
                  Close
                </button>
                {loading ? (
                  <button type="submit" className="btn btn-success me-2">
                    <Loader />
                  </button>
                ) : (
                  <button type="submit" className="btn btn-success me-2">
                    Create Category
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateCategoryModal;
