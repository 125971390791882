import axiosInstance from "../../../Axios/axios";

const UpdateYalidineApi = async (id,data) => {
    return await axiosInstance.post(`store/delivery_company/yalidine/update/${id}`,data);
};

const getYalidineApi = async (id) => {
    return await axiosInstance.get(`store/delivery_company/yalidine/${id}`);
};

const getSyncTarifsApi = async (id) => {
    return await axiosInstance.get(`store/delivery_company/yalidine/${id}/sync_tarifs`);
};

const getTarifCommunes = async (page,id,wilaya_id) => {
    return await axiosInstance.get(`store/delivery_company/yalidine/${id}/wilaya/${wilaya_id}`);
};

export {
    UpdateYalidineApi,
    getYalidineApi,
    getSyncTarifsApi,
    getTarifCommunes
}
