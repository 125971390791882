import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/common/Breadcrumb";
import { useEffect, useState } from "react";
import useAllFunctionOrderLeads from "../../../hooks/app/orderLeads/useAllFunctionOrderLeads";
import { useForm } from "react-hook-form";
import Loader from "../../../components/common/general/Loader";
import { errorMessage } from "../../../helpers/errorMessage";
import Toast from "../../../helpers/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpdateLeadStatusSchema } from "../../../validation/app/leads/UpdateLeadStatusSchema";
import InputComponent from "../../../components/FormUpdate/InputComponent";
import useAgentLeads from "../../../hooks/agent/Leads/useAgentLeads";
import Loading from "../../Loading";
import { Link } from "react-router-dom/dist";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import ViewLeadAddProductDialog from "./ViewLeadComponents/ViewLeadAddProductDialog";
import ViewLeadAssignToComponent from "./ViewLeadComponents/ViewLeadAssignToComponent";

const ViewLeads = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [sources, setSources] = useState([]);
  const [sourceLoading, setSourceLoading] = useState(false);
  const [DeliveryWarning, setDeliveryWarning] = useState(<></>);
  const [wilayas, setWilayas] = useState([]);
  const [wilayasLoading, setWilayasLoading] = useState(false);
  const [dairas, setdairas] = useState([]);
  const [dairaLoading, setDairaLoading] = useState(false);
  const [DeleteItemLoading, setDeleteItemLoading] = useState(false);
  const [deliveryCost, setDeliveryCost] = useState(0);
  const [deliveryCostAnou, setDeliveryCostAnou] = useState(0);
  const [total, setTotal] = useState(0);
  const [checkProductBtn, setCheckProductBtn] = useState(false);
  const [assignToBoolean, setAssignToBoolean] = useState(false);
  const [assignTo, setAssignTo] = useState(<></>);
  const {
    getOrderLeadDetails,
    getOrderLeadStatus,
    getUpdateOrderLeadStatus,
    getUpdateOrderLead,
    getWilayas,
    getWilayaDairas,
    getLeadSources,
    getCheckDelivery
  } = useAllFunctionOrderLeads();

  const {getConfirmeLead,getDeleteLeadItem } = useAgentLeads();

  useEffect(() => {
    getOrderLeadStatus(id, setData);
    getOrderLeadDetails(id, setLoadingData, setData);
    getLeadSources(setSources);
    getWilayas(setWilayas,setWilayasLoading);
  }, [id]);
  const {
    setValue,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: data,
  });
  const {
    register: registerStatus,
    handleSubmit: handleSubmitStatus,
    formState: { errors: errorsStatus },
  } = useForm({
    resolver: yupResolver(UpdateLeadStatusSchema),
  });
  const wilaya_id = register("wilaya_id");
  const daira_id = register("daira_id");
  const delivery_type = register("delivery_type");
  const assign_to = register("assign_to", 1);
  useEffect(() => {
    if (data) {
      var final = 0;
      data.items?.map((item) => {
        if (item.productItems?.length === 0) {
          final += item.qty * item.product?.price;
        } else {
          item.productItems.map((item_temp) => {
            final += item_temp.qty * item_temp.product_item?.price;
          });
        }
      });
      let total = deliveryCost - deliveryCostAnou;
      setTotal(final + total);
    }
  }, [data, deliveryCostAnou, deliveryCost]);
  const CheckDelivery = (ev) => {
    const values = getValues();
    if (
      values.delivery_type !== "" &&
      values.delivery_type !== null &&
      values.delivery_type !== undefined &&
      values.wilaya_id !== "" &&
      values.wilaya_id !== null &&
      values.wilaya_id !== undefined &&
      values.daira_id !== "" &&
      values.daira_id !== null &&
      values.daira_id !== undefined
    ) {
      setDeliveryWarning(<></>);
      getCheckDelivery(
        data?.id,
        values,
        setDeliveryWarning,
        setDeliveryCost,
        setDeliveryCostAnou
      );
      setCheckProductBtn(true);
    } else {
      setCheckProductBtn(false);
      setDeliveryWarning(<></>);
      setDeliveryCost(0);
      setDeliveryCostAnou(0);
    }
  };
  const onSubmit = (temp_data) => {
    if (data) {
      if (data.items.length === 0) {
        Toast("error", "Please add at least one product");
      } else {
        getConfirmeLead(id, temp_data, setLoadingData, setData);
      }
    } else {
      Toast("error", "Please wait until loading data");
    }
  };
  const handleChangeWilaya = (e) => {
    setValue("daira_id", null);
    getWilayaDairas(e.target.value, setdairas,setDairaLoading);
  };
  const handleAssignTo = (e) => {
    if (
      e.target.value !== "" &&
      e.target.value !== null &&
      e.target.value !== undefined
    ) {
      if (e.target.value == 1) {
        setAssignToBoolean(true);
        setAssignTo(
          <ViewLeadAssignToComponent
            assign_to={assign_to}
            CheckDelivery={CheckDelivery}
            errors={errors}
          />
        );
      } else {
        setAssignTo(<></>);
        setAssignToBoolean(false);
      }
    } else {
      setAssignTo(<></>);
      setAssignToBoolean(false);
    }
  };
  const SubmitUpdateStatus = (temp_data) => {
    getUpdateOrderLeadStatus(id, temp_data, setLoading);
  };
  const DeleteProduct = (item_id) => {
    getDeleteLeadItem(data.lead?.id, item_id, setDeleteItemLoading, setData);
  };
  const UpdateItem = (e, att) => {
    if (e.keyCode === 13) {
      let temp_data;
      e.preventDefault();
      switch (att) {
        case "first_name":
          temp_data = { first_name: e.currentTarget.textContent };
          break;
        case "last_name":
          temp_data = { last_name: e.currentTarget.textContent };
          break;
        case "phone":
          temp_data = { phone: e.currentTarget.textContent };
          break;
        case "phone_secondary":
          temp_data = { phone_secondary: e.currentTarget.textContent };
          break;
        case "address":
          temp_data = { address: e.currentTarget.textContent };
          break;
        case "source_value":
          temp_data = { source_value: e.currentTarget.textContent };
          break;
        case "note":
          temp_data = { note: e.currentTarget.textContent };
          break;
      }
      getUpdateOrderLead(id, temp_data, setLoading, setData);
    }
  };
  return (
    <>
      <Breadcrumb
        title="Leads"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="View & Update Lead"
        secondaryLink="/leads"
        secondarytitle="Leads"
      />
      {loadingData ? (
        <Loading />
      ) : (
        <>
         <ViewLeadAddProductDialog
            id={id}
            setData={setData}
            wilaya_id={getValues("wilaya_id")}
            daira_id={getValues("daira_id")}
            delivery_type={getValues("delivery_type")}
            assign_to={getValues("assign_to")}
          />
          <div className="row">
            <div className="col-lg-7">
              <div className="card">
                <div className="card-header">Main Informations</div>
                <div className="card-body">
                  <form
                    className="forms-sample"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                          <div className="d-flex align-items-center hover-pointer">
                            <div className="ms-2">
                              <p className="tx-13 text-muted">First name</p>
                              <p
                                className="tx-18 p-1"
                                contenteditable="true"
                                onKeyDown={(e) => UpdateItem(e, "first_name")}
                              >
                                {data?.first_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                          <div className="d-flex align-items-center hover-pointer">
                            <div className="ms-2">
                              <p className="tx-13 text-muted">Last name</p>
                              <p
                                className="tx-18 p-1"
                                contenteditable="true"
                                onKeyDown={(e) => UpdateItem(e, "last_name")}
                              >
                                {data?.last_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                          <div className="d-flex align-items-center hover-pointer">
                            <div className="ms-2">
                              <p className="tx-13 text-muted">Phone</p>
                              <p
                                className="tx-18 p-1"
                                contenteditable="true"
                                onKeyDown={(e) => UpdateItem(e, "phone")}
                              >
                                {data?.phone}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                          <div className="d-flex align-items-center hover-pointer">
                            <div className="ms-2">
                              <p className="tx-13 text-muted">
                                Phone Secondary
                              </p>
                              <p
                                className="tx-18 p-1"
                                contenteditable="true"
                                onKeyDown={(e) =>
                                  UpdateItem(e, "phone_secondary")
                                }
                              >
                                {data?.phone_secondary}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                          <div className="d-flex align-items-center hover-pointer">
                            <div className="ms-2">
                              <p className="tx-13 text-muted">Adresse</p>
                              <p
                                className="tx-18 p-1"
                                contenteditable="true"
                                onKeyDown={(e) => UpdateItem(e, "address")}
                              >
                                {data?.address}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                          <div className="d-flex align-items-center hover-pointer">
                            <div className="ms-2">
                              <p className="tx-13 text-muted">Note</p>
                              <p
                                className="tx-18 p-1"
                                contenteditable="true"
                                onKeyDown={(e) => UpdateItem(e, "note")}
                              >
                                {data?.note}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          assignToBoolean === true ? "col-lg-3" : "col-lg-4"
                        }
                      >
                        <div className="mb-3">
                          <label for="wilaya_id" className="form-label">
                            Wilaya:{" "}
                            <span className="text-success">{data?.wilaya}</span>
                          </label>
                          <select
                            name="wilaya_id"
                            id="wilaya_id"
                            className="form-control"
                            {...wilaya_id}
                            onChange={(e) => {
                              wilaya_id.onChange(e);
                              handleChangeWilaya(e);
                              CheckDelivery(e);
                            }}
                          >
                            {wilayasLoading === true ? (
                              <option value="">Loading....</option>
                            ) : (
                              <option value="">Selectionner</option>
                            )}
                            {wilayas?.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                          {errorMessage(errors, "wilaya_id")}
                        </div>
                      </div>
                      <div
                        className={
                          assignToBoolean === true ? "col-lg-3" : "col-lg-4"
                        }
                      >
                        <div className="mb-3">
                          <label for="daira_id" className="form-label">
                            Dairas:{" "}
                            <span className="text-success">{data?.daira}</span>
                          </label>
                          <select
                            name="daira_id"
                            id="daira_id"
                            className="form-control"
                            {...daira_id}
                            onChange={(e) => {
                              daira_id.onChange(e);
                              CheckDelivery(e);
                            }}
                          >
                            {dairaLoading === true ? (
                              <option value="">Loading....</option>
                            ) : (
                              <option value="">Selectionner</option>
                            )}
                            {dairas?.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                          {errorMessage(errors, "daira_id")}
                        </div>
                      </div>
                      <div
                        className={
                          assignToBoolean === true ? "col-lg-3" : "col-lg-4"
                        }
                      >
                        <div className="mb-3">
                          <label for="delivery_type" className="form-label">
                            Delivery To:{" "}
                            <span className="text-success">
                              {data?.delivery_type}
                            </span>
                          </label>
                          <select
                            name="delivery_type"
                            id="delivery_type"
                            className="form-control"
                            {...delivery_type}
                            onChange={(e) => {
                              delivery_type.onChange(e);
                              CheckDelivery(e);
                              handleAssignTo(e);
                            }}
                          >
                            <option value="">Selectionner</option>
                            <option value={1}>Home</option>
                            <option value={2}>Stop Desk</option>
                          </select>
                          {errorMessage(errors, "delivery_type")}
                        </div>
                      </div>
                      {assignTo}
                      <div className="col-lg-12">{DeliveryWarning}</div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label for="source_id" className="form-label">
                            Source
                          </label>
                          <select
                            name="source_id"
                            id="source_id"
                            className="form-control"
                            {...register("source_id")}
                          >
                            {sourceLoading === true ? (
                              <option value="">Loading...</option>
                            ) : (
                              <option value="">Selectionner</option>
                            )}
                            {sources?.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                          {errorMessage(errors, "source_id")}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                          <div className="d-flex align-items-center hover-pointer">
                            <div className="ms-2">
                              <p className="tx-13 text-muted">Source value</p>
                              <p
                                className="tx-18 p-1"
                                contenteditable="true"
                                onKeyDown={(e) => UpdateItem(e, "source_value")}
                              >
                                {data?.source_value}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {loadingData ? (
                        <button type="submit" className="btn btn-primary me-2">
                          <Loader />
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-success me-2">
                          Confirme Lead
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                        <div className="d-flex align-items-center hover-pointer">
                          <div className="ms-2">
                            <p className="tx-13 text-muted">Product</p>
                            <p className="tx-18 p-1">{data.lead?.product}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                        <div className="d-flex align-items-center hover-pointer">
                          <div className="ms-2">
                            <p className="tx-13 text-muted">Quantity</p>
                            <p className="tx-18 p-1">{data.lead?.qty}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {checkProductBtn === true ? (
                      <button
                        className="btn btn-success me-2"
                        data-bs-toggle="modal"
                        data-bs-target="#LeadAddProduct"
                      >
                        Add Product
                      </button>
                    ) : (
                      <Link
                        className="btn btn-success me-2"
                        onClick={(e) => {
                          Toast(
                            "error",
                            "Please Seleect Wilaya, Daira and Delivery type first"
                          );
                        }}
                      >
                        Add Product
                      </Link>
                    )}
                  </div>
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Qty</th>
                            <th>Price</th>
                            <th>Total Price</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.lead?.items.map((item, index) => {
                            return (
                              <tr>
                                <th>{item.product?.name}</th>
                                {item.productItems?.length == 0 ? (
                                  <th>{item.qty}</th>
                                ) : (
                                  <th>
                                    {item.productItems.map(
                                      (temp_item, index) => {
                                        return (
                                          <>
                                            <span key={index}>
                                              {temp_item.product_item?.name} :{" "}
                                              {temp_item.qty}
                                            </span>
                                            <br />
                                          </>
                                        );
                                      }
                                    )}
                                  </th>
                                )}
                                {item.productItems?.length == 0 ? (
                                  <th>{item.product?.price} Da</th>
                                ) : (
                                  <th>
                                    {item.productItems.map(
                                      (temp_item, index) => {
                                        return (
                                          <>
                                            <span key={index}>
                                              {temp_item.product_item?.name} :{" "}
                                              {temp_item.product_item?.price} Da
                                            </span>
                                            <br />
                                          </>
                                        );
                                      }
                                    )}
                                  </th>
                                )}
                                {item.productItems?.length == 0 ? (
                                  <th>{item.product?.price * item.qty} Da</th>
                                ) : (
                                  <th>
                                    {item.productItems.map(
                                      (temp_item, index) => {
                                        return (
                                          <>
                                            <span key={index}>
                                              {temp_item.product_item?.price *
                                                temp_item.qty}{" "}
                                              Da
                                            </span>
                                            <br />
                                          </>
                                        );
                                      }
                                    )}
                                  </th>
                                )}
                                <th>
                                  <div className="d-flex flex-row justify-between">
                                    {DeleteItemLoading === true ? (
                                      <Link>
                                        <FeatherIcon icon="loader" size={20} />
                                      </Link>
                                    ) : (
                                      <Link
                                        onClick={(e) => DeleteProduct(item.id)}
                                      >
                                        <FeatherIcon
                                          icon="trash"
                                          color="red"
                                          size={20}
                                        />
                                      </Link>
                                    )}
                                  </div>
                                </th>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>DP Origin</td>
                            <td>{deliveryCost} Da</td>
                            <td>DP Annouce</td>
                            <td>{deliveryCost - deliveryCostAnou} Da</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>Final Price</td>
                            <td>{total} Da</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-title">Status History</div>
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Remarque</th>
                          <th>Added by</th>
                          <th>Created at</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.status?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <th>{item.status?.name}</th>
                              <th>{item.remarque}</th>
                              {item.added_by != null ? (
                                <th>
                                  {item.added_by?.first_name +
                                    " " +
                                    item.added_by?.last_name}
                                </th>
                              ) : (
                                <th></th>
                              )}
                              <th>{item.created_at}</th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ViewLeads;
