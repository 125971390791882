import { useEffect, useState } from "react";
import DeliveryCompany from "../../../../../hooks/app/DeliveryCompany/DeliveryCompany";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { CreateYalidineSchema } from "../../../../../validation/app/deliveryCompany/CreateYalidineSchema";
import Loader from "../../../../../components/common/general/Loader";
import { errorMessage } from "../../../../../helpers/errorMessage";
import useAgentLeads from "../../../../../hooks/agent/Leads/useAgentLeads";

const YalidineCompany = () => {
  const [loading, setLoading] = useState(false);
  const [wilayas, setWilayas] = useState([]);
  const [wilayasLoading, setWilayasLoading] = useState([]);
  const { getWilayas } = useAgentLeads();
  const { CreateYalidineCompany } = DeliveryCompany();
  useEffect(() => {
    getWilayas(setWilayasLoading, setWilayas);
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateYalidineSchema),
    mode: "onChange",
  });
  const onSubmit = (data) => {
    CreateYalidineCompany(data, setLoading);
  };
  return (
    <>
      <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-6">
            <div className="mb-3">
              <label for="api_token" className="form-label">
                Api Token
              </label>
              <input
                type="text"
                className="form-control"
                id="api_token"
                placeholder="Api Token"
                {...register("api_token")}
              />
              {errorMessage(errors, "api_token")}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mb-3">
              <label for="api_id" className="form-label">
                Api ID
              </label>
              <input
                type="text"
                className="form-control"
                id="api_id"
                placeholder="Api ID"
                {...register("api_id")}
              />
              {errorMessage(errors, "api_id")}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-3">
              <label for="depart_wilaya_id" className="form-label">
                Wilaya:
              </label>
              <select
                name="depart_wilaya_id"
                id="depart_wilaya_id"
                className="form-control"
                {...register("depart_wilaya_id")}
              >
                {wilayasLoading === true ? (
                  <option value="">Loading....</option>
                ) : (
                  <option value="">Selectionner</option>
                )}
                {wilayas?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {errorMessage(errors, "depart_wilaya_id")}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-3">
              <label for="status" className="form-label">
                Status
              </label>
              <select
                name="status"
                id="status"
                className="form-control"
                {...register("status")}
              >
                <option value="">Selectionner</option>
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
              {errorMessage(errors, "status")}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-3">
              <label for="default" className="form-label">
                Default
              </label>
              <select
                name="default"
                id="default"
                className="form-control"
                {...register("default")}
              >
                <option value="">Selectionner</option>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </select>
              {errorMessage(errors, "default")}
            </div>
          </div>
        </div>
        {loading ? (
          <button type="submit" className="btn btn-primary me-2">
            <Loader />
          </button>
        ) : (
          <button type="submit" className="btn btn-success me-2">
            Create
          </button>
        )}
      </form>
    </>
  );
};
export default YalidineCompany;
