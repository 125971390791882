import { useQuery } from "@tanstack/react-query";
import { getTarifCommunes } from "../../../../apis/app/delivery/company/HandleYalidineApi";

export const useYalidine = (page,id,wilaya_id) => {
  const { data: Datas, isLoading: loadingDatas } = useQuery({
    queryKey: ["yalidine communes", page],
    queryFn: () => getTarifCommunes(page,id,wilaya_id),
  });
  return { Datas, loadingDatas };
};
